import './resources.scss';

import React from 'react';

import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import SubHeader from '../../components/SubHeader';

const downloads = [

  {
    title: 'Debit Card',
    forms: [
      {
        name: 'How to use it',
        url: '/documents/Debit_Card_Info.pdf'
      },
      {
        name: 'Lost Card/Additional Card',
        url: '/documents/Debit_Card_Request.pdf'
      }
    ]
  },
  {
    title: 'Claim Forms and Tools',
    forms: [
      {
        name: 'FSA Eligible Expenses',
        url: '/documents/Health_Care_FSA_Eligible_Expenses.pdf'
      },
      {
        name: 'HCRA/DCRA Accounts',
        url: '/documents/FSA_Claim_Form.pdf'
      },
      {
        name: 'HCRL Account',
        url: '/documents/LPFSA_Claim_Form.pdf'
      },
      {
        name: 'HRA Account',
        url: '/documents/HRA_Claim_Form.pdf'
      },
      {
        name: 'Parking/Transit Accounts',
        url: '/documents/Tran_Park_Claim_Form.pdf'
      },
      {
        name: 'Medical Necessity',
        url: '/documents/Medical_Necessity_Form.pdf'
      }
    ]
  },
  {
    title: 'Direct Deposit',
    forms: [
      {
        name: 'Direct Deposit Authorization',
        url: '/documents/Direct_Deposit_Form.pdf'
      },
      {
        name: 'Void Check Request',
        url: '/documents/VOID_CK_REQUEST.pdf'
      }
    ]
  },
];

const downloads2 = [
  {
    title: 'COBRA Forms',
    forms: [
      {
        name: 'Frequently Asked Questions',
        url: '/documents/COBRA_FAQ.pdf'
      },
      {
        name: 'Qualifying Event',
        url: '/documents/COB_QE_Notice.pdf'
      },
      {
        name: 'Second Qualifying Event',
        url: '/documents/COB_Second_QE_Notice.pdf'
      },
      {
        name: 'Notice of Disability',
        url: '/documents/COB_Disability_Notice.pdf'
      },
      {
        name: 'Other Coverage, Medicare or Cessation of Disability',
        url: '/documents/COB_Notice_of_Other_Cov.pdf'
      },
      {
        name: 'Direct Pay Authorization',
        url: '/documents/Direct Pay Form.pdf'
      }
    ]
  },
  {
    title: 'HIPAA',
    forms: [
      {
        name: 'HIPAA Release',
        url: '/documents/HIPAA_Release_Form.pdf'
      }
    ]
  },
  {
    title: 'Privacy',
    forms: [
      {
        name: 'Privacy Policy',
        url: '/documents/Website_Privacy_Notice.pdf'
      }
    ]
  }

];

const downloads3 = [

  {
    title: 'IRS Publications',
    groups: [
      {
        title: 'Health Care',
        forms: [
          {
            name: 'IRS Publication 502 (Health Care)',
            url: 'https://www.irs.gov/pub/irs-pdf/p502.pdf'
          }
        ]
      },
      {
        title: 'Dependent Care',
        forms: [
          {
            name: 'IRS Publication 503 (Dependent Care)',
            url: 'https://www.irs.gov/pub/irs-pdf/p503.pdf'
          }
        ]
      },
      {
        title: 'HSAs and Other Tax-Favored Health Plans',
        forms: [
          {
            name: 'IRS Publication 969 (HSAs and Other Tax-Favored Health Plans)',
            url: 'https://www.irs.gov/pub/irs-pdf/p969.pdf'
          }
        ]
      },
      {
        title: 'Other IRS Publications',
        forms: [
          {
            name: 'Other IRS Publications',
            url: 'http://www.IRS.gov'
          }
        ]
      }
    ]
  }
];

function downloadFormList(forms, key) {
  return (
    <ul className="download-list">
      {forms.map((form, i) => (
        <li
          key={`form-${i}`}
          className="download-item"
        >
          <a className="download-link" href={form.url} target="_blank">{form.name}</a>
        </li>
      ))}
    </ul>
  )
}

function renderDownloadSection(download, i) {
  if(download.groups) {
    return (
      <React.Fragment key={i}>
        <h3 className="download-list-heading">{download.title}</h3>
        <div className="download-grid">
          {download.groups.map((group, j) => (
            <div className="download-grid__group" key={`group-${j}`}>
              <h4 className="download-list-heading download-list-heading--group">{group.title}</h4>
              {downloadFormList(group.forms)}
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment key={i}>
      <h3 className="download-list-heading">{download.title}</h3>
      {downloadFormList(download.forms)}
    </React.Fragment>
  )
}

const ResourcesPage = () => (
  <Layout>
    <SEO title="Resources" />
    <SubHeader title="Helpful Resources" />
    <div className="container">

      <PageHeader title="" message="" />
      <section className="downloads">
        <div className="downloads__col">
          {downloads.map(renderDownloadSection)}
        </div>
        <div className="downloads__col">
          {downloads2.map(renderDownloadSection)}
        </div>
      </section>
      <section className="downloads downloads-bottom">
        <div className="downloads__col">
          {downloads3.map(renderDownloadSection)}
        </div>
      </section>
    </div>
  </Layout>
);

export default ResourcesPage
